import React from "react";
import { Flex, Layout, Typography, theme as antdTheme } from "antd";
import { LargeBusinessAvatar } from "../CustomerCasePage/ui/LargeBusinessAvatar/LargeBusinessAvatar";
import { TelegramIcon } from "./ui/Icons/TelegramIcon";
import { WebSiteIcon } from "./ui/Icons/WebSiteIcon";
import { BackButton } from "./ui/BackButton/BackButton";
import { Link } from "react-router-dom";
const { Content } = Layout;

export const CompanyPage = () => {
  const { token } = antdTheme.useToken();

  return (
    <Layout>
      <Content style={{ padding: token.paddingLG }}>
        <Link to="/case">
          <BackButton />
        </Link>
        <LargeBusinessAvatar
          avatarSrc="https://avatar.iran.liara.run/public"
          title="Alina Kabayeva's Agency"
        />
        <Typography style={{ textAlign: "center" }}>
          Forget about long queues and the hassle of paperwork. Now, you can
          apply for a visa online and get it instantly!
        </Typography>
        {/* TODO(): add space here */}
        <Flex
          flex="1"
          gap={10}
          style={{
            marginTop: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* TODO(): add Link react-router */}
          <Link to="https://google.com">
            <TelegramIcon />
          </Link>
          <Link to="https://yandex.com">
            <WebSiteIcon />
          </Link>
        </Flex>
        {/* <h1>made with trackinglu</h1> */}
      </Content>
    </Layout>
  );
};
