import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Flex, Avatar, Typography } from "antd";
const { Title } = Typography;

// TODO():move it to shared
type Url = string;

interface LargeBusinessAvatarProps {
  avatarSrc: Url;
  title: string;
}

export const LargeBusinessAvatar = (props: LargeBusinessAvatarProps) => {
  const { avatarSrc, title } = props;

  return (
    <Flex vertical align="center">
      <Avatar src={avatarSrc} size={155} icon={<UserOutlined />} />
      <Title style={{ textAlign: "center" }} level={3}>
        {title}
      </Title>
    </Flex>
  );
};
