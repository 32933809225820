import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Flex, Avatar, Typography } from "antd";

// TODO():move it to shared
type Url = string;

interface BusinessAvatarProps {
  avatarSrc: Url;
  title: string;
}

export const BusinessAvatar = (props: BusinessAvatarProps) => {
  const { avatarSrc, title } = props;

  return (
    <Flex gap={5} align="center">
      <Avatar src={avatarSrc} size="large" icon={<UserOutlined />} />
      <Typography style={{ color: "#ffffff" }}>{title}</Typography>
    </Flex>
  );
};
