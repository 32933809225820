import { Flex, Typography } from "antd";
import React, { ReactElement } from "react";

interface TextWithIconProps {
  text: string;
  Icon: ReactElement;
}
export const TextWithIcon = (props: TextWithIconProps) => {
  const { Icon, text } = props;

  return (
    <Flex gap={5}>
      <Icon />
      <Typography>{text}</Typography>
    </Flex>
  );
};
