import React from "react";

export const UserIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      opacity={0.3}
    >
      <path d="M4.191 17.097V8.58c0-2.964 1.646-4.8 4.67-4.79h8.064c3.025 0 4.68 1.836 4.68 4.799v8.508c0 2.955-1.684 4.8-4.756 4.8H8.862c-3.025 0-4.67-1.845-4.67-4.8Z" />
      <path d="M9.72 17.697c0-1.206.953-2.709 3.695-2.709 2.744 0 3.696 1.492 3.696 2.699" />
      <path
        d="M15.775 10.349a2.36 2.36 0 1 1-4.72 0 2.36 2.36 0 0 1 4.72 0Z"
        clipRule="evenodd"
      />
      <path d="M2.604 8.777h3.31m-3.31 8.128h3.31" />
    </g>
  </svg>
);
