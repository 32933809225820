import React from "react";
import { RouterProvider } from "react-router-dom";
import { App as AntdApp, ConfigProvider } from "antd";

import { router } from "./router";

const customGlobalTokens = {
  colorPrimary: "#1890FF",
};

// TODO(): move theme to another folder
const theme = {
  token: {
    // TODO(): add fontFamily
    fontSize: 16,
    fontSizeHeading3: 24,
    colorPrimary: customGlobalTokens.colorPrimary,
  },
  components: {
    Layout: {
      bodyBg: "white",
      headerBg: customGlobalTokens.colorPrimary,
    },
    Progress: {
      // TODO(check later): Progress: primary color doesn't overrides the component
      // I have to explicitly specify defaultColor here because Progress doesn't use
      // colorPrimary
      defaultColor: customGlobalTokens.colorPrimary,
    },
    Steps: {
      // TODO(fix later): Steps: numbers are not vertically centered
      // Somehow numbers in Steps components aren't vertically centered
      // that's why I have to add this magic number
      iconTop: 7,
    },
  },
};

export const App = () => {
  return (
    <ConfigProvider theme={theme}>
      <AntdApp>
        <RouterProvider router={router} />
      </AntdApp>
    </ConfigProvider>
  );
};
