import React, { SVGProps } from "react";

export const WebSiteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
  >
    <path
      fill="#40A9FF"
      d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M24 37.5c7.456 0 13.5-6.044 13.5-13.5S31.456 10.5 24 10.5 10.5 16.544 10.5 24 16.544 37.5 24 37.5Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M24 37.5c2.485 0 4.5-6.044 4.5-13.5S26.485 10.5 24 10.5s-4.5 6.044-4.5 13.5 2.015 13.5 4.5 13.5Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M24 28.5c7.456 0 13.5-2.015 13.5-4.5s-6.044-4.5-13.5-4.5-13.5 2.015-13.5 4.5 6.044 4.5 13.5 4.5Z"
    />
  </svg>
);
