// TODO(): remove React import
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Progress,
  Steps,
  Typography,
  Layout,
  Flex,
  Card,
  theme as antdTheme,
} from "antd";
const { Header } = Layout;
const { Title } = Typography;
// TODO(): fix long paths
import { TextWithIcon } from "./ui/TextWithIcon/TextWithIcon";
import { CalendarIcon } from "./ui/Icons/CalendarIcon";
import { UserIcon } from "./ui/Icons/UserIcon";
import { BusinessAvatar } from "./ui/BusinessAvatar/BusinessAvatar";

export const CustomerCasePage = () => {
  let { caseId } = useParams();
  const { token } = antdTheme.useToken();
  const description = "This is a description.";

  return (
    <Layout style={{ height: "100vh" }}>
      {/* TODO(): make header more dark (because on some photos it will be impossible to see white text) */}
      <Header
        style={{
          height: 250,
          padding: `30 ${token.paddingLG} 0`,
          backgroundImage: "url(https://picsum.photos/900/900)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Link to="/company">
          <BusinessAvatar
            avatarSrc="https://avatar.iran.liara.run/public"
            title="Alina Kabayeva's Agency"
          />
        </Link>
      </Header>
      {/* TODO(!):нужно убрать тут border снизу */}
      <Flex vertical flex="1" style={{ position: "relative", top: "-5px" }}>
        {/* TODO(): Why this one is red? */}
        {/* TODO(): Refactor this piece */}
        <Card style={{ height: "100%" }}>
          <Flex vertical gap={20}>
            <Title level={3}>Spanish Shengen</Title>
            <Flex gap={15}>
              {/* TODO(): this API is not so good, it is ugly */}
              <TextWithIcon Icon={CalendarIcon} text="5 May" />
              <TextWithIcon Icon={UserIcon} text="Alex O." />
            </Flex>
            <Progress percent={80} />
            <Steps
              direction="vertical"
              current={3}
              items={[
                {
                  title: "Тут мы собираем информацию",
                  description:
                    "Расспрашиваем вас и ожидаем сердечных ответов. Это все очень важно!",
                },
                {
                  title: "Тут мы считаем сколько денег это будет стоить.",
                  description:
                    "Не бойтесь. Вас не придется продавать почки. Одну точно сохраните.",
                },
                {
                  title: "Тут мы дерзновенно беремся за работу.",
                  description:
                    "Одиссею потребовалось 10 лет чтобы вернуться на Итаку. Мы справимся быстрее.",
                },
                {
                  title: "Тут мы присылаем вам работу на согласование",
                  description:
                    "Тут вы в восторге, если что. В восторге и радости!",
                },
                {
                  title:
                    "Тут вы смотрите на нас с блаженной улыбкой. Благодарите за качество.",
                  description: "А мы вам доброжелательно машем рукой.",
                },
              ]}
            />
            {/* TODO(): <MadeWithTrackingly /> */}
          </Flex>
        </Card>
      </Flex>
    </Layout>
  );
};
