import React, { SVGProps } from "react";

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z"
    />
    <path
      fill="#fff"
      d="m9.988 23.792 16.161-6.659c1.596-.694 7.006-2.913 7.006-2.913s2.497-.971 2.29 1.387c-.07.971-.625 4.37-1.18 8.046l-1.734 10.89s-.139 1.596-1.318 1.873c-1.18.278-3.121-.97-3.468-1.248-.278-.208-5.202-3.33-7.006-4.856-.486-.416-1.04-1.248.07-2.22 2.497-2.288 5.48-5.132 7.283-6.936.832-.832 1.664-2.774-1.804-.416l-9.78 6.59s-1.11.693-3.19.069c-2.082-.624-4.51-1.457-4.51-1.457s-1.664-1.04 1.18-2.15Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={32.002}
        x2={20.002}
        y1={8.002}
        y2={36}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37AEE2" />
        <stop offset={1} stopColor="#1E96C8" />
      </linearGradient>
    </defs>
  </svg>
);
