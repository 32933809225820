import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";

// TODO(): connect to Ant Design theme
const COLOR = "gray";

export const BackButton = () => {
  return (
    <Flex gap={10} style={{ color: COLOR }}>
      <LeftOutlined />
      <Typography style={{ color: COLOR }}>Back</Typography>
    </Flex>
  );
};
